<script setup lang="ts"></script>

<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5 23H19C19.2765 23 19.5 22.776 19.5 22.5C19.696 18.0655 17.225 13.903 13.2695 12C17.2245 10.097 19.6965 5.934 19.5 1.5C19.5 1.224 19.2765 1 19 1H5C4.7235 1 4.5 1.224 4.5 1.5V2.067C4.5 6.3205 6.9625 10.1705 10.7305 12C6.9625 13.8295 4.5 17.6795 4.5 21.933V22.5C4.5 22.776 4.7235 23 5 23Z"
            fill="currentColor"
        />
    </svg>
</template>
